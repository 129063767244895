input,
select,
textarea {
	background-color: $gray-bg;
	@include font-size(12);
	@include padding($short: 1.5rem);
	font-family: $open;
}

input {
	&[type=radio] {
		border: 0;

		// .media-picker & {
		// 	@include relative(left 0);
		// 	@include padding($short: 0)
		// }
	}
}

textarea {
	resize: vertical;
	min-height: 15rem;
}

.inputs-checkbox {
	label {
		@include relative;
		@include padding($left: 2rem);
		cursor: pointer;

		&::before {
			content: '';
			@include size(1rem);
			border: .1rem solid $orange;
			@extend %position-center-v;
			left: 0
		}
	}

	input:checked {
		+label {
			&::before {
				background-color: $orange;
			}
		}
	}
}