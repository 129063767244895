.footer {
	background: $gray-dark;
	color: $white;

	&-block {
		@include padding($short: 0 2.5rem);

		@include bp('medium') {
			border-right: .1rem solid fade-out($white, .85);
			@include padding($short: 0 5rem);
		}

		@include bp('wide') {
			@include padding($short: 0 10rem);
		}

		&:first-of-type {
			@include bp('extra-large') {
				border-left: .1rem solid fade-out($white, .85);
			}
		}

		&:last-of-type {
			.title {
				border-bottom: 0;
			}
		}

		.title {
			@include relative;
			border-bottom: .1rem solid fade-out($white, .85);
			@include padding($short: 1.5rem);

			@include bp('medium') {
				@include padding($short: 0);
			}
		}
	}

	&-lower {
		border-top: .1rem solid fade-out($white, .85);
		@include padding($short: 3rem 0 3rem 5rem);

		@include bp('extra-large') {
			@include padding($short: 3rem 0 3rem 10rem);
		}

	}

	&-logo {
		@include margin($bottom: 2rem);

		&-img {
			max-width: 15rem;
		}
	}

	&-text {
		border-bottom: .1rem solid fade-out($white, .85);
		@include margin($bottom: 2.5rem);
		@include padding($short: 2.5rem 0);

		@include bp('medium') {
			@include margin($bottom: 5rem);
		}

		@include bp('extra-large') {
			border-bottom: 0;
			@include margin($bottom: 0);
		}

		.text {
			@include margin($bottom: 2rem)
		}
	}

	&-social {
		@include bp('extra-large') {
			@include margin($left: -1rem)
		}
	}
}