.accordion {

	&-title,
	&-content {
		@include padding($short: 2.5rem 0);
	}

	&-block {
		border-bottom: .1rem solid fade-out($gray, .5);
	}

	&-title {
		opacity: .75;
		@include relative;
		cursor: pointer;

		span {
			max-width: 95%;
			display: block;
		}

		&::after {
			content: '+';
			@extend %position-center-v;
			right: 0;
		}

		&.active {
			&::after {
				content: '\2013'
			}
		}
	}

	&-content {
		&>div {
			@include margin($bottom: 2rem);
			opacity: .75;
		}
	}
}