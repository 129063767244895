*,
*::before,
*::after {
	box-sizing: border-box
}


body {
	overflow-x: hidden
}

a {
	@extend %outline-accesibility;
}

input,
button,
select,
textarea {
	@extend %outline-accesibility;

}

[tabindex] {
	@extend %outline-accesibility;
}



a {
	color: inherit;
	text-decoration: none;
}

//remove default styles
input,
button,
select,
textarea {
	border: 0;
	font-size: inherit;
	appearance: none;
	@include margin($short: 0);
	@include padding($short: 0);
	font-family: inherit;
	outline: 0;
	background: transparent;
}

select {

	//remove IE default arrow
	&::-ms-expand {
		display: none;
	}

	//applies rules to the value of a text or password <input> control or the content of a <select> control. 
	&::-ms-value {
		background: none;
		color: $gray-darker //change color according to project
	}
}

//remove clear buttom from IE
input {
	&::-ms-clear {
		width: 0;
		height: 0;
	}

	//applies rules to the value of a text or password <input> control or the content of a <select> control. 
	&::-ms-value {
		background: none;
		color: $gray-darker //change color according to project
	}

	&[type=checkbox],
	&[type=file],
	&[type=radio] {
		@extend %hide-element;
	}

	//remove X from IE
	&[type=search] {

		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}

		//remove X from Chrome
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}
	}
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

// style when selected mouse
::selection {
	background-color: $orange;
	color: $white;
}

.full-width {
	@include margin($left: -3%, $right: -3%)
}

.container {

	&-flex {
		@extend %flex-center;
		@include relative;

		&::after {
			content: '';
			@include absolute(top 0 left 0 right 0 bottom 0);
			background-color: fade-out($gray-darker, .4);
			@include size(100%)
		}
	}

	&-fixed {
		max-width: 120rem;
		@extend %block-center;
	}

}

.has-arrow {
	@include relative;

	&::after {
		content: '';
		background-image: url('/Themes/Avenue/FvrAssets/img/arrow-big-bg.svg');
		background-repeat: no-repeat;
		background-position: 0 20%;
		background-size: 200%;
		@include absolute(top 0 left 0);
		@include size(100%);

		@include bp('large') {
			background-position: 50% 17%;
			background-size: 50%;
		}
	}

	&.media-picker {
		&::after {
			background-position: 450% -20vw;
			background-size: 95%;

			@include bp('small') {
				background-position: 450% 42%;
			}

			@include bp('medium') {
				background-position: 450% 25%;
			}

			@include bp('large') {
				background-position: 50% 17%;
				background-size: 50%;
			}
		}
	}
}

.privacy-terms {
	@include size(100%, auto);
	@include margin($short: 1rem 0 0);
	@include font-size(12);
	max-width: 28rem;
	@extend %flex-wrap;

	@include bp('medium') {
		max-width: 38rem;
	}

	input {
		&:checked {
			+label {
				&::before {
					content: '\2714';
				}
			}
		}
	}

	&-label {
		@include relative;

		@include padding($left: 2rem);

		&::before {
			content: '';
			@extend %position-center;
			left: -1rem;
			background-color: $white;
			@include size(1.6rem);
			@extend %flex-center;
			color: $orange;
		}
	}

	.field-error {
		@include margin($bottom: 1rem)
	}
}

.field-error {
	color: #f00;

}

//fixes 
.product-selectors,
.page-title.with-subcategories,
.pager {
	display: none;
}

.store-closed-page {
	@extend %flex-column-center;
	@include font-size(16);
}