.media {
	text-align: left;

	@include bp('medium') {
		@include _('flex-wrap')
	}

	&-container {
		@include bp('medium') {
			@include _('flex-wrap')
		}
	}

	&-reverse {
		@include bp('medium') {
			flex-direction: row-reverse;
		}
	}

	&-bg {
		@include bp('medium') {
			min-height: 40rem;
			background: no-repeat center / cover;

			.ie11 & {
				@include size(auto, 40rem);
				overflow: hidden;
			}
		}

		@include bp('large') {
			min-height: 70rem;

			.ie11 & {
				@include size(auto, 70rem);
			}
		}

		@include bp('extra-wide') {
			min-height: 100rem;

			.ie11 & {
				@include size(auto, 100rem);
			}
		}
	}

	&-column {
		flex-direction: column;
		background-color: $gray-bg;

		@include bp('medium') {
			@include size(calc(50% - 1rem), auto);
		}

		@include bp('large') {
			@include size(calc(50% - 1.5rem), auto);
		}

		&:first-of-type {
			@include bp('medium') {
				@include margin($right: 2rem)
			}

			@include bp('large') {
				@include margin($right: 3rem);

			}
		}

		&-full-bg {
			flex-direction: column;
			background-color: $gray-bg;
			@include padding($short: 0);
			@include size(100%, auto);
			@include relative;
			cursor: pointer;
			@include margin($bottom: 3rem);

			.media-related & {
				background-color: $white;
			}

			@include bp('medium') {
				@include size(calc(100%/3 - 1.4rem), auto);
			}

			@include bp('large') {
				@include size(calc(100%/3 - 2rem), auto);

				.ie11 & {
					@include size(calc(100%/3 - 2.1rem), auto);
				}
			}

			&:not(:nth-of-type(3n+3)) {
				@include bp('medium') {
					@include margin($right: 2rem)
				}

				@include bp('large') {
					@include margin($right: 3rem)
				}
			}

			&-link {

				@include padding($short: 3rem);
				display: block;

				@include bp('extra-large') {
					@include padding($short: 4rem 8rem)
				}

				&::after {
					content: '';
					@include absolute(top 0 left 0 right 0 bottom 0);
					@include size(100%);
					background: linear-gradient(180deg, fade-out($gray-darker, .5) 0%, $gray-darker 100%);

					.media-column-full-bg-mood & {
						opacity: 0;
						@include transition(opacity 1s ease-in-out);
					}
				}

				&:not(.media-column-full-bg-mood) {
					&::after {
						@include scaleY(0);
						transform-origin: bottom center;
						@include transition(transform .3s);

					}
				}

				&:hover {
					&::after {
						@include scaleY(1);
					}

					+.media-column-full-bg-link-more,
					~.media-column-full-bg-mood-image {
						opacity: 1;
					}
				}

				&-no-hover {
					&::after {
						display: none
					}
				}

				&-more {
					opacity: 0;
					@include transition(opacity 1s ease-in-out);
					@extend %position-center-h;
					bottom: 5rem;
					pointer-events: none;
					@include z-index('sidewalk');

					@include bp('medium') {
						min-width: 70%;
					}

					@include bp('extra-large') {
						min-width: auto;
					}
				}
			}

			&-mood {

				&::after {
					display: none;
				}

				&-image {
					opacity: 0;
					@include transition(opacity 1s ease-in-out);
					@include absolute(top 0 left 0 right 0 bottom 0);
					pointer-events: none;
				}

			}
		}
	}

	&-col {
		.media-column & {
			&.media-picture {
				//max-height: 35rem;
				overflow: hidden;
			}
		}

		@include bp('medium') {
			@include size(50%, auto);

			.media-column & {
				@include size(100%, auto);
			}

			.media-column-full-bg & {
				@include size(auto)
			}
		}
	}

	&-picture {

		background-color: $gray-bg-box;

		.media-bg & {
			background-color: transparent;
		}

		.media-column-full-bg & {
			@include margin($bottom: 2rem);

			@include bp('medium') {
				@include size(auto, 14rem);
			}

			@include bp('extra-large') {
				@include size(auto, 28rem);
			}
		}
	}

	&-details {
		overflow: hidden;
		background: $gray-bg url('/Themes/Avenue/FvrAssets/img/arrow-bg.svg') no-repeat 1000% 30% / 95%;
		@extend %flex-column-center-v;
		@include padding($short: 3rem);

		@include bp('extra-large') {
			@include padding($short: 0 8rem);

			.media-column & {
				@include padding($short: 4rem 8rem)
			}

			.full-width & {
				@include padding($short: 9rem 13rem);
			}
		}

		.media-column-full-bg & {
			@include padding($short: 0);
		}


		.media-picker &,
		.media-no-bg & {
			background-image: none;
		}

		.media-bg & {
			@include relative;
			@include z-index('sidewalk');

			@include bp('medium') {
				background: none;

			}
		}

		.media-related & {
			background-color: $white;
		}
	}

	&-title {
		@include margin($bottom: 1.5rem);

		.media-column-full-bg & {
			@include margin($bottom: 0)
		}

		.container-flex & {
			@extend %position-center;
			color: $white;
			@include z-index('sidewalk')
		}

		.media-bg & {
			@include padding($short: 0) // fix override plugin
		}

		.media-related & {
			text-align: center;
		}

		.columns & {
			@include margin($bottom: 3.5rem)
		}
	}

	&-text {
		@include margin($bottom: 3rem);

		&-center {
			text-align: center
		}

		li {
			@include relative;
			@include padding($left: 2rem);
			@include margin($bottom: 2rem);

			&::before {
				content: '';
				background: url('/Themes/Avenue/FvrAssets/img/bullet-orange.svg') no-repeat;
				@include size(1.3rem, .9rem);
				@extend %position-center-v;
				left: 0;
			}
		}
	}

	&-buttons {

		@extend %flex-center-v;

		input,
		button {
			background-color: $white;
			@include padding($short: 1rem);
			@include margin($right: .5rem);

			&.add-to-cart-button {
				@include size(auto, 4rem)
			}
		}

		input,
		button:not(.add-to-cart-button) {
			@include size(4rem)
		}
	}

	&-button {

		+.media-link {
			@include margin($top: 3rem);

			@include bp('large') {
				@include margin($top: 7rem);
			}
		}
	}

	&-link {
		align-self: flex-start;

		&:not(.media-link-small) {
			@extend %flex-center-v;

			&:hover {
				img {
					@include translate(1rem, 0)
				}
			}

			span {
				@include margin($right: 1rem)
			}

			img {
				@include transition(.25s ease-in-out)
			}
		}

		.media-related & {
			align-self: center;
		}
	}

	&-image {

		&-flexible {
			display: flex;
		}

		&.x-center {
			display: block;
			width: 100%;
		}

		.media-bg & {
			min-height: 33rem;

			@include bp('medium') {
				display: none;
			}
		}

		.media-image-flexible & {
			@include bp('medium') {
				flex: 1;

				&:not(:last-of-type) {
					@include margin($right: 3rem)
				}
			}
		}

	}

	&-info {
		&-button {
			@include margin($right: 3rem)
		}

		&-detail {
			@include margin($bottom: 6rem)
		}
	}

	&-picker {

		&-container {
			@include margin($bottom: 2.5rem);
		}

		&-label {
			@include size(1.6rem);
			background-color: $gray-darker;
			@include relative;
			@include border-radius(50%);
			@include margin($right: 1.5rem);
			display: inline-flex;
			justify-content: center;

			&.active {
				&::after {
					content: '';
					opacity: .25;
					@include size(3.2rem);
					border: .2rem solid $gray-darker;
					@include border-radius(50%);
					@extend %position-center;

					.media-bg & {
						border-color: $white;
					}
				}
			}
		}
	}

	&-slider {
		@include size(100%, auto);
		@include margin($bottom: 3rem);

		&-image {
			max-height: 11.5rem;

			@include bp('medium') {
				@include _('block-center');
				display: block;
			}
		}

		&.swiper-container {
			@extend %swiper-bullets;
			@include padding($bottom: 4.5rem);

			.swiper-pagination {
				bottom: 1.5rem;
				left: 1.5rem;
				@include size(auto);

				@include bp('medium') {
					@include size(100%, auto);
				}
			}
		}
	}

	&-related {
		background-color: $gray-bg;
		@include padding($short: 5rem 4rem 2rem);


		@include bp('extra-large') {
			@include padding($short: 10rem 8rem 5rem)
		}

		>.media-title {
			@include margin($bottom: 5rem)
		}
	}

}