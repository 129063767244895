.columns {
	background-color: $gray-bg;
	text-align: left;
	@include padding($short: 5rem 3rem 5em 5rem);

	@include bp('medium') {
		@include padding($short: 10rem 8rem 10rem 12rem);
	}

	&-list {
		@extend %flex-wrap;
	}

	&-item {
		@include relative;
		@include size(100%, auto);
		@include margin($bottom: 3.5rem);

		@include bp('medium') {
			@include size(calc(100%/2 - 5rem), auto);

			&:nth-of-type(2n+1) {
				@include margin($right: 10rem)
			}
		}

		@include bp('large') {
			@include size(calc(100%/3 - 5rem), auto);

			&:nth-of-type(2n+1) {
				@include margin($right: 0)
			}

			&:not(:nth-of-type(3n+3)) {
				@include margin($right: 7rem)
			}
		}

		&-title {
			@include padding($bottom: 2rem);
			@include margin($bottom: 2rem);
			border-bottom: .1rem solid fade-out($gray, .5);
		}

		&-text {
			p {
				@include margin($bottom: 2rem);
			}

			strong {
				display: block;
			}
		}
	}

	&-button {
		@extend %flex-center-h;

		a {
			@extend .btn;
			@extend .btn-orange;

		}
	}

	img {
		@include absolute(top 0 left -4.5rem)
	}
}