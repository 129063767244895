.btn {
	border-width: .2rem;
	border-style: solid;
	border-color: currentColor;
	@include padding($short: 1.5rem 4rem);
	@include transition(.25s ease-in);
	align-self: flex-start;
	cursor: pointer;

	&-orange {
		color: $orange;

		.media-bg & {
			@include bp('medium') {
				background-color: $orange;
				color: $white;
				border-color: $orange;
			}
		}

		.slider-hp-description & {
			background-color: $orange;
			color: $white;
			border-color: $orange;
		}

		&:hover {
			background-color: $orange;
			color: $white;

			.media-bg & {
				@include bp('medium') {
					background-color: $white;
					color: $orange;
					border-color: $white;
				}
			}

			.slider-hp-description & {
				background-color: $white;
				color: $orange;
				border-color: $white;
			}
		}
	}

	&-white {
		color: $white;

		&-bg {
			border-color: $white;
			background-color: $white;
			color: $gray-darker;

			&:hover {
				border-color: $gray-darker;
				color: $white;
				background-color: $gray-darker;
			}
		}
	}
}