//include only the following properties:
// font-family
// font-size
// font-weight
// line-height
// text-decoration
// text-align
// text-transform
// color

html {
	font-size: $base-font;
}

body {
	font-family: $open;
}

label {
	.inputs & {
		@include font-size(16);

		@include bp('medium') {
			@include font-size(20);
		}

		&.form-text-small {
			@include font-size(12);
			line-height: line-height(15, 12);
			color: $gray;
			text-transform: none;
			font-weight: 400;
		}
	}

}

.btn {
	@include font-size(12);
	line-height: line-height(17, 12);
	@include font-tracking(120);
	font-weight: 700;
	text-transform: uppercase;

	@include bp('large') {
		@include font-size(14);
		line-height: line-height(19, 14);
		@include font-tracking(140);
	}
}


// blocks sort by order A-Z

.accordion {
	text-align: left;

	&-title {
		@include font-size(18);
		line-height: line-height(22, 18);
		color: $gray-darker;

		&::after {
			@include font-size(30);

			@include bp('large') {
				@include font-size(35);
			}
		}

		&.active {
			color: $orange;
			font-weight: 600;
		}

		@include bp('large') {
			@include font-size(24);
			line-height: line-height(28, 24);
		}
	}

	&-content {
		@include font-size(13);
		line-height: line-height(22, 13);
		color: $gray-darker;

		@include bp('large') {
			@include font-size(16);
			line-height: line-height(28, 16);
		}
	}
}

.footer {

	&-block {

		.title {
			@include font-size(16);
			line-height: line-height(22, 16);
			@include font-tracking(160);
			text-transform: uppercase;
		}
	}

	&-disclaimer {
		color: $white;
		@include font-size(12);
		line-height: line-height(17, 12);
		@include font-tracking(.86);

		span {
			color: $orange;
		}
	}

	&-text {
		text-align: center;

		@include bp('extra-large') {
			text-align: left;
		}

		.text {
			@include font-size(16);
			line-height: line-height(22, 16);
			font-style: italic;

			@include bp('medium') {
				@include font-size(24);
				line-height: line-height(33, 24);
			}

			br {
				display: none;

				@include bp('extra-large') {
					display: block;
				}

			}
		}
	}
}

.columns {
	&-item {
		&-title {
			color: $orange;
			text-transform: uppercase;
			font-weight: 800;
			line-height: line-height(30, 15);
			@include font-size(15);

			@include bp('large') {
				line-height: line-height(30, 20);
				@include font-size(20);
			}
		}

		&-text {
			color: $gray-darker;
			@include font-size(13);
			line-height: line-height(19, 13);

			@include bp('large') {
				@include font-size(16);
				line-height: line-height(22, 16);
			}
		}
	}
}

.media {
	&-details {
		color: $gray-darker;
	}

	&-title {
		text-transform: uppercase;
		font-weight: 800;
		@include font-size(30);
		line-height: line-height(41, 30);

		@include bp('large') {
			@include font-size(45);
			line-height: line-height(61, 45);
		}

		.media-column-full-bg & {
			@include font-size(17);
			line-height: line-height(23, 17);


			@include bp('large') {
				@include font-size(30);
				line-height: line-height(41, 30);
			}
		}

		.media-bg & {
			@include bp('medium') {
				color: $white;
			}
		}

		.columns & {
			text-align: center;
		}
	}

	&-text {
		opacity: .75;
		line-height: line-height(28, 16);
		@include font-size(16);
		color: $gray-darker;

		.slider-hp & {
			color: $white;
		}


		.media-bg & {
			@include bp('medium') {
				color: $white;
			}

			&-low {
				line-height: line-height(18, 16);
			}
		}

		a {
			@include margin($top: 3rem);
			display: block;
			text-transform: uppercase;
			color: $orange;
			font-weight: 700;
			@include font-size(12);
			line-height: line-height(17, 12);
			@include font-tracking(120);

			@include bp('large') {
				@include font-size(14);
				line-height: line-height(19, 14);
				@include font-tracking(140);
			}
		}
	}

	&-link {
		text-transform: uppercase;
		opacity: .5;
		font-weight: 700;
		@include font-size(12);
		@include font-tracking(120);
		line-height: line-height(17, 12);


		@include bp('large') {
			@include font-size(16);
			@include font-tracking(160);
		}

		.media-bg & {
			@include bp('medium') {
				color: $white;
			}
		}

		&-small {
			line-height: line-height(15, 11);
			@include font-tracking(79);
			@include font-size(11);

			@include bp('large') {
				line-height: line-height(19, 14);
				@include font-tracking(100);
				@include font-size(14);
			}

			.columns & {
				text-align: center;
			}
		}
	}

	&-info {
		color: $orange;
		@include font-size(24);
		line-height: line-height(33, 24);
		@include font-tracking(80);
		font-weight: 600;

		&-text {
			color: $orange;
			line-height: line-height(15, 11);
			@include font-tracking(79);
			@include font-size(11);
			text-transform: uppercase;

			@include bp('large') {
				line-height: line-height(19, 14);
				@include font-tracking(100);
				@include font-size(14);
			}
		}
	}

	&-buttons {

		input,
		button {
			color: $gray-darker;
			@include font-size(12);
			line-height: line-height(17, 12);
			@include font-tracking(120);
			font-weight: 700;
			text-transform: uppercase;
			text-align: center;

			@include bp('large') {
				@include font-size(14);
				line-height: line-height(19, 14);
				@include font-tracking(140);
			}

			&::before,
			&::after {
				font-weight: 700;
			}
		}
	}
}

.modal {
	&-title {
		color: $orange;
		text-transform: uppercase;
		font-weight: 800;
		line-height: line-height(30, 15);
		@include font-size(15);

		@include bp('large') {
			line-height: line-height(30, 20);
			@include font-size(20);
		}
	}

	&-table {
		&-title {
			text-transform: uppercase;
			font-weight: 700;
			@include font-size(12);
			line-height: line-height(15, 11);
			@include font-tracking(79);
			@include font-size(11);
			background: $gray-bg;

			@include bp('large') {
				line-height: line-height(19, 14);
				@include font-tracking(100);
				@include font-size(14);
			}
		}
	}
}

.newsletter {
	&-email {
		font-weight: 700;
		@include font-size(14);
		line-height: line-height(19, 14);
		@include font-tracking(140);
	}

	&-subscribe {

		&-text {
			color: $orange;

			&::placeholder {
				text-transform: uppercase;
				color: currentColor;
				font-weight: 700;
				text-align: center;

				@include bp('medium') {
					text-align: left;
				}
			}
		}

		&-button {
			text-transform: uppercase;
			color: $white;
			font-weight: 700;
		}
	}

	.title {
		text-align: center;
		color: $gray-darker;
		@include font-size(13);
		line-height: line-height(18, 13);

		@include bp('medium') {
			@include font-size(24);
			line-height: line-height(33, 24);
		}
	}
}

.topic-page {
	a {
		color: $orange;
		text-decoration: underline;
		font-weight: 700;
	}
}