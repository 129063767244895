.newsletter {
	background-color: $gray-lighter;
	@include box-shadow(inset 0 -.1rem 0 fade-out($white, .85));
	@include padding($short: 5rem 0);

	&-subscribe {
		@extend %flex-center;

		&-button {
			@include padding($short: 1.5rem 3rem);
			background-color: $orange;
			@include size(100%);

			@include bp('medium') {
				@include size(auto)
			}
		}

		&-text {
			@include padding($short: 1.5rem 3rem);
			@include size(100%, auto);

		}

		&.fake-block {
			.title {
				display: none;

				@include bp('large') {
					display: inline-block;
					visibility: hidden;
				}
			}
		}
	}

	&-email {
		@extend %flex-center-v;
		background-color: $white;
		max-width: 80%
	}

	.title {
		@include margin($bottom: 3rem);
		@include size(100%, auto);

		@include bp('large') {
			@include size(auto);
			@include margin($right: 3rem, $bottom: 0);
		}

		&::before,
		&::after {
			display: none;
		}

	}
}