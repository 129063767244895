.video {
	@include relative;
	@include size(100%, 0);
	@include padding($bottom: 56.25%);
	overflow: hidden;

	&-play {
		@extend %position-center;
		background-color: fade-out($white, .75);
		border: .4rem solid $white;
		@include border-radius(50%);
		@include size(6.25rem);


		@include bp('medium') {
			@include size(12.5rem);
		}

		&::after {
			content: '';
			@include size(0);
			border-style: solid;
			border-width: 1.25rem 0 1.25rem 2.16rem;
			border-color: transparent transparent transparent $white;
			display: block;
			@extend %block-center;

			@include bp('medium') {
				border-width: 2.5rem 0 2.5rem 4.33rem;
			}
		}
	}

	&-pause {
		@include absolute(bottom -5rem left 0 right 0);
		@include size(100%, 5rem);
		z-index: z-index('sidewalk');
		background: linear-gradient(to top, fade-out($gray, .5) 0%, fade-out($white, .9) 35%, fade-out($white, .9) 100%);
		opacity: 0;

		span {
			@extend %position-center-v;
			background-color: fade-out($white, .75);
			border: .1rem solid $white;
			@include border-radius(50%);
			@include size(2.5rem);
			color: $white;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			font-family: monospace;
			@include font-size(.9);
		}

	}

	video {
		@include absolute(top 0 right 0 bottom 0 left 0);
		@include size(100%);
	}
}