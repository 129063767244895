.modal {
	background: fade-out($white, .5);
	@extend %flex-center;
	z-index: z-index('blackhole');
	text-align: left;

	&-title {
		@include margin($short: 2rem 0);
	}

	&-close {
		@include absolute(right 3.5rem top 2.5rem);
		background-color: $gray;
		color: $white;
		@include size(2.4rem);
		@include padding($short: .5rem);
		@include border-radius(50%);
		@include font-size(14);
		text-align: center;
		font-weight: 700;
		cursor: default;
	}

	&-table {
		@include font-size(14);
		@include size(100%, auto);
		background-color: $white;
		color: $gray;

		&-wrap {
			@include relative;
			@include size(60rem, auto);
			@include margin($short: 0 5%);
			@include padding($short: 0 4rem 4rem);
			background-color: $white;
			@extend %flex-center;
			@include box-shadow(0 0 3rem 0 fade-out($gray, .85))
		}

		td {
			@include padding($short: 1.5rem);
			border: .1rem solid fade-out($gray, .5);
		}
	}
}