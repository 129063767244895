.header {
	&-wrapper {
		@include position(sticky, top 0);
		z-index: z-index('blackhole');
		@include size(100%);

		&.active {

			@include fixed;
		}
	}

	&-logo {
		.logo {
			max-width: 15rem;
		}
	}

	.mega-menu {
		.dropdown,
		.sublist-wrap {
			display: none;
		}
	}
}