.slider-hp {

	&-description {
		@extend %position-center-v;
		left: 5rem;
		color: $white;
		max-width: 50%;
		@include z-index('sidewalk');

		@include bp('large') {
			left: 10rem;
			max-width: 35%;
		}
	}

	&-button {
		display: inline-flex;
	}

	//FIX hack to override css plugin
	&.swiper-container {

		.swiper {
			&-pagination {
				bottom: 5rem;
				left: 5rem;
				@include size(auto);

				@include bp('large') {
					bottom: 10rem;
					left: 10rem;
				}
			}
		}

		@extend %swiper-bullets
	}
}